<template>
    <form @submit.prevent="$emit('queryUpdate')" @change="handleInput" class="form-sm">
        <!-- Show Search Only Default -->
        <div class="form-group">
            <input @input="query.sort_by = 'relevance'" v-model="query.q" class="form-control"
                placeholder="Search username, city, state." />
        </div>

        <!-- Location -->
        <address-filters
            @input="$emit('queryUpdate')"
            countries_with="users"
            :query="query"
            ref="addressFilters"
        />

        <div class="row">
            <div class="form-group col-sm-6">
                <select v-if="!isPremium" v-model="query.genre" class="form-control">
                    <option value="">All Genres</option>
                    <option v-for="g in genres" :value="g.id" v-text="g.genre"></option>
                </select>
                <genre-select
                    v-else :isFilter="true"
                    @update:modelValue="updateGenres"
                    v-model="genreIntegers">
                </genre-select>
            </div>

            <div class="form-group col-sm-6 d-flex flex-column">
                <div class="d-flex">
                    <a @click.prevent="$emit('toggleOrder')" class="d-flex align-items-center mr-1 btn btn-secondary">
                        <i v-if="query.sort_order === 'ascending'" class="fa fa-sort-amount-asc"></i>
                        <i v-else class="fa fa-sort-amount-desc"></i>
                        Sort
                    </a>
                    <select v-model="query.sort_by" class="form-control">
                        <option value="follower_count">Popularity</option>
                        <option value="display_name">Name</option>
                        <option value="created_at">Date Created</option>
                        <option v-if="showDistance" value="distance">Distance</option>
                        <option v-if="query.q" value="relevance">Relevance</option>
                    </select>
                </div>
            </div>
        </div>

        <div class="row" v-if="isLogged">
            <div class="form-group col-sm-6">
                <div class="form-check">
                    <input @click="$emit('queryUpdate')" class="form-check-input" id="onlyShowFollowedCheck" type="checkbox"
                        v-model="query.only_show_followed" />
                    <label class="form-check-label" for="onlyShowFollowedCheck"> Only Show Followed</label>
                </div>
            </div>
        </div>

        <div class="d-flex justify-content-between align-items-end">
            <div class="d-flex align-items-end">
                <select v-model="query.type" class="mr-1 form-control">
                    <option value="">All Users</option>
                    <option v-for="type in types" :value="type.value">
                        {{ type.label }}
                    </option>
                </select>
                <slot name="map"></slot>
            </div>

            <slot name="submit"></slot>
        </div>
    </form>
</template>

<script>
/* global bus location */
import addressFilters from '../addressFilters.vue';

export default {
    name: 'users-filter',

    components: { addressFilters },

    props: {
        query: {
            type: Object,
            required: true,
        },

        showDistance: {
            type: Boolean,
            default: false,
        },
    },

    data() {
        return {
            genres: [],
            types: [
                {
                    value: 'agent',
                    label: 'Agent',
                },
                {
                    value: 'band',
                    label: 'Band/Artist',
                },
                {
                    value: 'label',
                    label: 'Label',
                },
                {
                    value: 'manager',
                    label: 'Manager',
                },
                {
                    value: 'promoter',
                    label: 'Promoter',
                },
                {
                    value: 'venue',
                    label: 'Venue',
                },
                {
                    value: 'other',
                    label: 'Other',
                },
            ],
        };
    },

    created() {
        this.fetchGenres();
    },

    computed: {
        // must provide integers not strings
        genreIntegers() {
            return this.query.genres.map(g => parseInt(g));
        },

        isPremium() {
            return window.User.is_premium;
        },

        isLogged() {
            return window.User !== false;
        },

        searchTerm() {
            return this.query.q;
        },
    },

    watch: {
        searchTerm() {
            if (this.query.q.length > 1) {
                this.$emit('queryUpdate');
            }
        },
    },

    methods: {
        // needed for non premium genre single select
        fetchGenres() {
            var $v = this;
            return axios
                .get('/api/genres')
                .then(function (response) {
                    $v.genres = response.data.data;
                })
                .catch(function (error) {
                    $v.genreFetchFailed = error.data;
                });
        },

        updateGenres(genres) {
            this.query.genres = genres;
            this.$emit('queryUpdate');
        },

        handleInput() {
            const vm = this;
            // instead of this.$nextTick
            setTimeout(() => {
                if (!(vm.query.q.length > 0 && vm.query.q.length < 2)) {
                    vm.$emit('queryUpdate');
                }
            });
        },
    },
};
</script>
