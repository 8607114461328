<template>
    <div class="container">
        <div class="row">
            <div class="col">
                <a @click="toggleModal" class="mb-1 mb-sm-0 btn btn-block btn-dark text-white">
                    Email Selected ({{ quickpitchCount }})
                </a>
            </div>
            <div class="col">
                <btn-state :state="emailAllState" @click="emailAll" btnClass="btn btn-block btn-dark text-white">
                    Email All ({{ venueCount }})
                </btn-state>
            </div>
            <div class="d-none d-sm-flex col">
                <a @click="resetQuickpitch" class="btn btn-block btn-dark text-white"> Reset QuickPitch </a>
            </div>
            <div v-if="emailAllState == 'error'" class="m-2 w-100 alert alert-danger">
                You must select
                <span v-for="(index, error) in errors.errors">
                    <span v-if="error == 'state' && (errors.has('genres') || errors.has('capacity'))">and </span>
                    {{ replaceFirst(error, '_') }}<span v-if="error == 'zip'"> code</span><span v-if="error == 'city'"> or
                    </span>
                    <span v-else-if="error != 'zip'">, </span>
                </span>
                to use 'Email All'.
            </div>
        </div>

        <!-- Modal -->
        <div class="modal fade" id="qp-modal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
            aria-hidden="true">
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="exampleModalLabel">QuickPitch Recipients</h5>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="scroll-container">
                        <table v-if="quickpitch.venues.data.length" class="table table-hover">
                            <thead>
                                <tr>
                                    <th>Name</th>
                                    <th>Location</th>
                                    <th class="text-right">Remove</th>
                                </tr>
                            </thead>
                            <tbody class="bg-white">
                                <tr v-for="venue in quickpitch.venues.data">
                                    <td>
                                        <a :href="'/venues/' + venue.slug">{{ venue.title }}</a>
                                    </td>
                                    <td>{{ cityState(venue) }}</td>
                                    <td class="text-right">
                                        <a @click="removeVenue(venue)" class="p-1 btn text-danger btn-link">
                                            <i class="fa fa-trash-o"></i>
                                        </a>
                                    </td>
                                </tr>
                            </tbody>
                        </table>

                        <div v-else class="m-2 text-center alert alert-info">
                            Use checkboxes to add venues to QuickPitch.
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button class="btn btn-secondary" data-dismiss="modal" aria-label="Close">Close</button>
                        <div v-show="quickpitch.venues.data.length">
                            <button @click="clearQuickpitch" class="btn btn-secondary">
                                Clear<span class="d-none d-sm-inline"> Venues</span>
                            </button>
                            <a :href="quickpitchEditUrl" class="btn btn-primary ml-2"> Compose QuickPitch </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <deluxe-modal v-model="showDeluxeModal"></deluxe-modal>
    </div>
</template>

<script>
/* global $ */
import Errors from '../../form/errors.js';
import deluxeModal from '../deluxeModal.vue';
import btnState from '../btnState.vue';
import {
    cityState,
    replaceFirst,
} from "../../filters/index.js";

export default {
    components: { btnState, deluxeModal },

    props: {
        quickpitch: {
            type: Object,
            required: true,
        },
        venueCount: {
            type: Number,
            default: 0,
        },
    },

    name: 'venue-quickpitch',

    data() {
        return {
            showDeluxeModal: false,
            modal: '',
            errors: {},
            emailAllState: 'none',
        };
    },

    created() {
        this.errors = new Errors();
    },

    mounted() {
        this.$nextTick(() => {
            this.modal = $('#qp-modal');
        });
    },

    computed: {
        quickpitchCount() {
            if (this.quickpitch.venues) {
                return this.quickpitch.venues.data.length;
            }
            return 0;
        },

        quickpitchEditUrl() {
            return '/quickpitch/' + this.quickpitch.uuid + '/edit';
        },

        isDeluxe() {
            return window.User.is_deluxe;
        },
    },

    watch: {
        venueCount() {
            this.emailAllState = 'none';
        },
    },

    methods: {
        cityState,
        replaceFirst,
        resetQuickpitch() {
            if (!window.User.is_deluxe) {
                this.showDeluxeModal = true;
                return;
            }

            this.errors.clear();
            this.emailAllState = 'none';

            this.$emit('quickpitchUpdate', [], true);
        },

        removeVenue(venue) {
            var newVenues = this.quickpitch.venues.data
                .map(i => i.uuid)
                .filter(i => {
                    return i !== venue.uuid;
                });

            this.$emit('quickpitchUpdate', newVenues);
        },

        clearQuickpitch() {
            this.$emit('quickpitchUpdate', []);
            this.toggleModal();
        },

        toggleModal() {
            if (!window.User.is_deluxe) {
                this.showDeluxeModal = true;
                return;
            }

            // TODO fetch qp to see if changes were made from venue results
            this.modal.modal('toggle');
        },

        emailAll() {
            if (!window.User.is_deluxe) {
                this.showDeluxeModal = true;
                return;
            }

            this.emailAllState = 'processing';
            axios
                .patch('/api/quickpitch-all/' + this.quickpitch.uuid + '/' + window.location.search)
                .then(() => {
                    window.location = this.quickpitchEditUrl;
                })
                .catch(e => {
                    this.errors.record(e.response.data.errors);
                    bus.$emit('emailAllError', this.errors);
                    this.emailAllState = 'error';
                });
        },
    },
};
</script>

<style scoped>
.scroll-container {
    max-height: 75vh;
    overflow: scroll;
}
</style>
